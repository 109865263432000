<template>
  <div
    class="form container my-10"
  >
    <h2>{{ isEdit ? 'Редактировать' :'Добавить' }} ТС {{ isEdit ? `[${params.item.number}]` :'' }}</h2>
    <v-row>
      <v-col cols="2">
        <v-text-field
          v-model="form.number"
          label="Номер"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="form.country_letter"
          label="Страна"
          auto-select-first
          :items="countries"
          item-text="text"
          item-value="letterCode"
          :filter="filterBySearchField"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col cols="7">
        <v-autocomplete
          v-model="form.vehicle_type"
          label="Тип"
          auto-select-first
          :items="vehicleTypes"
          item-text="text"
          item-value="code"
          :filter="filterBySearchField"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="form.transport_mark_name"
          label="Марка"
          auto-select-first
          :items="nsiTransportMarks"
          item-text="text"
          item-value="name"
          :filter="filterBySearchField"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="form.model_name"
          label="Модель"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>

      <v-col cols="3">
        <v-text-field
          v-model="form.vin"
          label="VIN"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.doc_number"
          label="Тех.паспорт"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="form.certificate_number"
          label="Сертификат"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
      <v-col cols="4">
        <custom-date-picker
          v-model="form.certificate_date"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </v-col>
    </v-row>
    <v-divider class="my-4" />
    <div class="d-flex mt-4">
      <v-btn
        class="mr-4"
        color="#5CB7B1"
        dark
        @click="submit"
      >
        Сохранить
      </v-btn>
      <v-btn
        color="red"
        dark
        @click="clear"
      >
        Очистить
      </v-btn>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import {
  getCountryNameByCode,
  getTransportCodeByName,
} from "@/helpers/catalogs";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";

export default {
  components: {CustomDatePicker},
  mixins:[filterBySearchFieldMixin],

  props:{
    params:{
      required:false,
      default:null,
      validator: (prop) => typeof prop === "object" || prop === null,
    }
  },
  data:() => ({
    form:{
      id: null,
      division_id: null,
      number: "",
      country_letter: "",
      vehicle_type: "",
      transport_mark_code: "",
      transport_mark_name: "",
      model_name:"",
      vin: "",
      doc_number: "",
      certificate_number:"",
      certificate_date:"",
    },
    nsiTransportMarks:[]
  }),
  computed:{
    ...mapGetters({
      countries: "catalogs/getNsiCountries",
      vehicleTypes:"catalogs/getNsiVehicleTypes",
      user:"auth/getUser"
    }),
    userId(){
      return this.user?.user?.id
    },
    isEdit(){
      return !!(this.params.item)
    },
    divisionId(){
      return this.user?.user?.activeDivisionId;
    }
  },
  watch:{
    params:{
      handler(nv){
        if(nv.item){
          this.setFields()
        }
      },
      deep:true,
      immediate:true,
    }
  },
  created(){
    this.loadTransportMarks()
  },
  methods:{
    loadTransportMarks(){
      this.$store.dispatch('catalogs/fetchNsiTransportMarks').then((items) => {
        this.nsiTransportMarks = items
      }).catch(() => {
        this.$snackbar({text: "Ошибка загрузки справочника",color:"red", top:false, right: false});
      })
    },
    setFields(){
      const item = this.params.item
      Object.keys(this.form).forEach(key => {
        if(key in item){
          this.form[key] = item[key]
        }
      })
    },
    submit(){
      const country_name = getCountryNameByCode(this.countries, this.form.country_letter)
      const division_id = this.isEdit ? this.form.division_id : this.divisionId
      const transport_mark_code = getTransportCodeByName(this.nsiTransportMarks, this.form.transport_mark_name)
      const payload = {
        ...this.form,
        user_id:this.userId,
        country_name,
        division_id,
        transport_mark_code
      }
      this.$store.dispatch("catalogs/completeUpdateVehicle", payload).then(() => {
        this.$snackbar({
          text: "Справочник обновлен",
          color: "blue",
          top: false,
          right: false,
        });
      }).catch(err => {
        if(err.response.status === 400){
          return this.$snackbar({text: err?.response?.data || "Ошибка",color:"red", top:false, right: false});
        }
        this.$snackbar({text: err || "Ошибка",color:"red", top:false, right:false});
      });
    },
    clear(){
      this.form = {
        id: null,
        division_id: null,
        number: "",
        country_letter: "",
        vehicle_type: "",
        transport_mark_code: "",
        transport_mark_name: "",
        vin: "",
        doc_number: ""
      }
    }
  }
}
</script>
<style scoped>
  .form{
    width:100%;
    min-height: calc(100vh - 129px)
  }
</style>
